import { GrClose } from 'react-icons/gr';
import { HiMenuAlt1 } from 'react-icons/hi';
import { css, Flex, Box, Label } from 'theme-ui';
import styled from '../../lib/transient-styled';
import { GreenButton, TextButton } from '../../shared-styled/blocks/buttons';

export const Container = styled(Flex)(
  css({
    position: 'sticky',
    top: '-1px',
    gap: ['22px', '20px', '12px'],
    justifyContent: ['space-between', 'space-between', 'normal'],
    backgroundColor: 'white',
    zIndex: '11',
  }),
  ({ $isStuck }) =>
    css({
      flexWrap: $isStuck
        ? ['wrap', 'wrap', 'wrap']
        : ['wrap', 'wrap', 'nowrap'],
      boxShadow: $isStuck ? '0px 4px 7px rgba(0, 0, 0, 0.1)' : null,
      padding: $isStuck
        ? ['16px 20px', '16px 40px', '12px 40px', '12px 50px', '12px 70px']
        : ['20px', '20px 40px', '16px 40px', '16px 50px', '16px 70px'],
    }),
  ({ $isModifiedHeaderOnPrint }) =>
    css({
      '@media print': $isModifiedHeaderOnPrint
        ? {
            padding: [
              '16px 20px',
              '16px 40px',
              '12px 40px',
              '12px 50px',
              '12px 70px',
            ],
            flexWrap: 'nowrap',
            boxShadow: 'none',
            position: 'static',
            justifyContent: 'start',
          }
        : {},
    }),
);

export const LogoWrapper = styled(Box)(
  css({
    width: ['135px', '145px', '175px'],
    height: ['38px', '48px'],
    mr: [0, 0, '35px', '90px', '70px'],
  }),
  ({ $isModifiedHeaderOnPrint }) =>
    css({
      '@media print': $isModifiedHeaderOnPrint
        ? {
            mt: '12px',
          }
        : {},
    }),
);

export const Auth = styled(Flex)(
  css({
    fontSize: ['0.75rem', 'inherit'],
    width: ['auto', 'auto', 'auto'],
    gap: ['8px', '12px', '15px', '15px', '21px'],
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginLeft: 'auto',
  }),
);

export const AuthButton = styled(TextButton)(
  css({
    minWidth: '67px',
    '&:hover': {
      background: 'white',
    },
  }),
);

export const AlignedGreenButton = styled(GreenButton)(
  css({
    py: 2,
    px: ['12px', '20px'],
    minWidth: ['110px'],
    maxHeight: '35px',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    justifyContent: 'center',
  }),
);

export const MidWrapper = styled(Flex)(
  css({}),
  ({ $isMiniBarActive, $isAlignedLeft, $isStuck, $shouldGrow = false }) =>
    css({
      justifyContent:
        $isMiniBarActive && $isAlignedLeft ? 'flex-end' : 'space-between',
      width: ['100%', '100%', 'calc(100% - 520px)'],
      ...($shouldGrow && { flexGrow: '1' }),
      ...($isStuck && {
        flexWrap: 'wrap',
        width: ['100%', '100%', 'auto'],
      }),
      '@media print': {
        width: 'auto',
      },
    }),
);

export const SearchAndPrintWrapper = styled(Flex)(css({}), ({ $isStuck }) =>
  css({
    ...($isStuck && {
      flexWrap: 'wrap',
      marginLeft: 'auto',
    }),
  }),
);

export const CloseNavIcon = styled(GrClose)(
  css({
    width: '28px',
    height: '24px',
    padding: '4px 6px',
    cursor: 'pointer',
  }),
);

export const NavIcon = styled(HiMenuAlt1)(
  css({
    width: '28px',
    height: '24px',
    cursor: 'pointer',
  }),
);

export const CloseLoginIcon = styled(GrClose)(
  css({
    position: 'absolute',
    width: '16px',
    top: '32px',
    right: '48px',
  }),
);

export const IconContainer = styled(Box)(
  css({
    alignItems: 'center',
  }),
);

export const MenuLabelContainer = styled(Flex)(
  css({
    alignItems: 'center',
    cursor: 'pointer',
  }),
);

export const MenuLabel = styled(Label)(
  css({
    marginLeft: '10px',
    cursor: 'pointer',
    fontFamily: 'Roboto',
  }),
);
